<!-- 仓库商品发货 -->
<template>
  <page-container class="send-warehouse-goods-container">
    <div class="send-card-item">
      <pay-address
          v-model="addressInfo"
          :loading.sync="addressLoading"
          @change="handleAddressChange"
      />
    </div>
    <div class="send-card-item warehouse-goods">
      <goods-info
          view-model="small"
          :value="{
              goodsImgSrc: warehouseGoodsInfo.imageUrl,
              goodsName: warehouseGoodsInfo.goodsName,
          }"
      >
        <template #price-right>
          <van-stepper
              v-model="form.count"
              :max="warehouseGoodsInfo.goodsCountNow"
              theme="round" disable-input
              @change="setCarriage"
          />
        </template>
      </goods-info>
      <p>请选择数量，共计：{{ warehouseGoodsInfo.goodsCountNow }}</p>
    </div>
    <div class="send-card-item">
      <van-field
          v-model="form.remark"
          type="textarea"
          rows="1"
          :autosize="true"
          :show-word-limit="true"
          maxlength="100"
          label-width="2.5em"
          label="备注"
          placeholder="请输入备注"
      />
    </div>

    <div v-if="form.carriage" class="send-card-item pay-way-container">
      <van-field
          label-width="2.5em"
          label="邮费"
          readonly
          input-align="right"
      >
        <template slot="input">
          <span style="color: red">￥{{ form.carriage }}</span>
        </template>

      </van-field>
      <van-radio-group v-model="form.payWay">
        <van-cell-group>
          <van-cell
              v-for="pay of payWayList"
              :title="pay.text"
              clickable
              @click="form.payWay = pay.type"
          >
            <template #title>
              <van-icon
                  :class="pay.iconName"
                  :name="pay.iconName"
              />
              {{ pay.text }}
              <span
                  v-if="pay.type === '04'"
                  class="user-balance-display-container"
              >
                ￥<span>{{ userBalance }}</span>
              </span>
            </template>
            <template #right-icon>
              <van-radio :name="pay.type"/>
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </div>

    <div class="send-btn-container">
      <van-button
          icon="tosend"
          round
          type="primary"
          block
          @click="send"
      >
        发 货
      </van-button>
    </div>

    <van-overlay
        v-if="loading"
        show
        z-index="9999"
        style="background-color: rgba(255, 255, 255, 0)"
    >
      <div style="display: flex;justify-content: center;align-items: center;height: 100%;">
        <van-loading size="24px" />
      </div>
    </van-overlay>

  </page-container>
</template>

<script>
import PayAddress from '@/views/payment/payAddress';
import GoodsInfo from '@/components/GoodsInfo';
import { Dialog, Toast } from 'vant';
import {
  getWarehouseAreaPostage,
  deliverGoodsCarriage,
  deliverWarehouseGoods,
} from '@/api/warehouse';
import { getUserAccount } from '@/api/user';
import { depositOrderWxPay, wxJsPay } from '@/util/wxJsPay';
import { objectGetter } from '@/util';

export default {
  name: 'sendWarehouseGoods',
  components: { GoodsInfo, PayAddress },
  data() {
    return {
      addressInfo: {},
      addressLoading: true,
      warehouseGoodsInfo: {},
      form: {
        carriage: 0,
        count: 1,
        remark: '',
        payWay: '01', // 支付方式
      },
      // 支付方式数据
      payWayList: [
        {
          iconName: 'wechat',
          text: '微信支付',
          type: '01',
        },
        {
          iconName: 'gold-coin',
          text: '余额支付',
          type: '04',
        }
      ],
      userBalance: '', // 用户余额
      loading: false,
    };
  },
  // computed: {
  //   totalCarriage() {
  //     const { carriage, count } = this.form;
  //     return +parseFloat(carriage * count);
  //   }
  // },
  created() {
    this.warehouseGoodsInfo = { ...this.$route.query };
    getUserAccount().then(res => {
      res = res || {};
      this.userBalance = res.amount || 0;
    });

  },
  methods: {
    handleAddressChange(address) {
      this.addressInfo = address || {};
      this.setCarriage();
    },

    setCarriage() {
      this.loading = true;
      const { warehouseId, orderId, goodsId } = this.warehouseGoodsInfo;
      const { provinceCode: areaId } = this.addressInfo;
      const { count } = this.form;
      getWarehouseAreaPostage({
        warehouseId,
        count,
        orderId,
        goodsId,
        areaId,
      }).then(carriage => {
        this.loading = false;
        this.form.carriage = carriage || 0;
      });
    },
    noCarriagePay() {
      let {
        name: revicerName,
        mobile: revicerMobile,
        provice: provinceName,
        provinceCode,
        city: cityName,
        cityCode,
        country: countryName,
        countryCode,
        street: streetName,
        streetCode,
        address,
      } = this.addressInfo;
      let params = {
        ...this.warehouseGoodsInfo,
        ...this.form,
        revicerName,
        revicerMobile,
        provinceName,
        provinceCode,
        cityName,
        cityCode,
        countryName,
        countryCode,
        streetName,
        streetCode,
        address,
      };
      this.loading = true;
      deliverWarehouseGoods(params).then(res => {
        this.loading = false;
        if (res === true) {
          this.setSendSuccess();
        }
      });
    },
    carriagePay() {
      this.loading = true;

      let { addressInfo, form, warehouseGoodsInfo } = this;
      let {
        name: revicerName,
        mobile: revicerMobile,
        provice: provinceName,
        provinceCode,
        city: cityName,
        cityCode,
        country: countryName,
        countryCode,
        street: streetName,
        streetCode,
        address,
      } = addressInfo;

      deliverGoodsCarriage({
        ...warehouseGoodsInfo,
        ...form,
        revicerName,
        revicerMobile,
        provinceName,
        provinceCode,
        cityName,
        cityCode,
        countryName,
        countryCode,
        streetName,
        streetCode,
        address,
      }, { headers: { getAllResponse: true } }).then(res => {
        this.loading = false;
        let { code, msg, data } = objectGetter(res, 'data') || {};
        let isPaySuccess = code === 0;
        if (!isPaySuccess)
          return Toast(msg || '请求失败.');

        if (form.payWay === '01') {
          depositOrderWxPay(data).then(res => {
            let isPaySuccess = res === true;
            if (isPaySuccess) {
              Toast('微信支付成功');
              this.setSendSuccess();
            }
          });
        } else if (form.payWay === '04') {
          Toast('余额支付成功');
          this.setSendSuccess();
        } else
          Toast('支付方式错误');
      });
    },
    send() {
      if (this.addressLoading)
        return console.log('addressLoading~~');

      if (!Object.keys(this.addressInfo).length)
        return Toast('请选择收货地址');

      Dialog.confirm({
        title: `是否确认发货`,
        // message: '弹窗内容',
        confirmButtonText: '确认',
      }).then(() => {
        const { carriage } = this.form;
        if (carriage) {
          console.log(`// todo: carriage pay`);
          this.carriagePay();
        } else
          this.noCarriagePay();

      }).catch(() => {
      });

    },
    setSendSuccess() {
      Toast('发货成功');
      this.$store.commit('warehouse/setRefreshing', true);
      this.$router.back();
    }
  },
};
</script>

<style lang="less" scoped>
.send-warehouse-goods-container {
  padding: 0 0 calc(80px + constant(safe-area-inset-bottom)) 0;
  padding: 0 0 calc(80px + env(safe-area-inset-bottom)) 0;

  .send-card-item {
    margin: 10px;
    background-color: #fff;
    border-radius: 15px;
    overflow: hidden;
  }

  .warehouse-goods {
    padding: 15px 15px 5px 15px;

    & > p {
      margin-bottom: 5px;
      text-align: right;
      font-size: 13px;
      color: #666;
    }
  }

  .pay-way-container {

    .van-icon {
      margin-right: 10px;
      font-size: 26px;
    }

    .wechat {
      color: #07C160;
    }

    .gold-coin {
      color: @primary-color;
    }

    .van-hairline--top-bottom::after, .van-hairline-unset--top-bottom::after {
      border: none;
    }

    .van-cell__title {
      display: flex;
      align-items: center;
    }

    .user-balance-display-container {
      margin-left: 1em;
      font-size: 14px;
      color: #666;
    }
  }


  .send-btn-container {
    position: fixed;
    z-index: 1;
    box-sizing: border-box;
    bottom: 0;
    left: 0;
    padding: 10px 20px calc(20px + constant(safe-area-inset-bottom)) 20px;
    padding: 10px 20px calc(20px + env(safe-area-inset-bottom)) 20px;
    width: 100%;
    background-color: #fff;
  }
}
</style>
